<template>
  <div>
    <v-container>
      <page-title title="Module">
        <template slot="action">
          <v-btn color="primary" @click="formAdd.dialog = !formAdd.dialog, $refs.refFormAdd.reset()">New</v-btn>
        </template>
      </page-title>
      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense elevation="0">
              <v-toolbar-title>List Data</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-text-field v-model="datatable.search" outlined dense text autofocus rounded placeholder="Search" clearable append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

              <v-btn icon @click="getData(true)" title="Refresh">
                <v-icon>mdi-sync</v-icon>
              </v-btn>
            </v-toolbar>
            <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="datatable.search" dense class="mb-3 my-0">
              <template v-slot:[`item.action`]="{ item }">
                <a href="javascript:;" @click="editData(item.id, true)" class="mx-1" title="Edit">
                  <v-icon small>mdi-pencil</v-icon>
                </a>
                <a href="javascript:;" @click="editPerm(item.id, true)" class="mx-1" title="Edit Perm">
                  <v-icon small>mdi-format-list-checks</v-icon>
                </a>
                <a href="javascript:;" @click="deleteData(item.id)" class="mx-1" title="Delete">
                  <v-icon small class="error--text">mdi-delete</v-icon>
                </a>
              </template>
              <template v-slot:[`item.is_active`]="{ item }">
                <v-icon small v-if="item.is_active == 1" class="success--text">mdi-check</v-icon>
                <v-icon small v-if="item.is_active == 0" class="error--text">mdi-cancel</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="formAdd.dialog" width="500">
      <v-form v-model="formAdd.valid" ref="refFormAdd" lazy-validation @submit.prevent="saveData">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Add Module</v-card-title>

          <v-card-text>
            <v-text-field label="Name" v-model="formAdd.formData.name" :error-messages="formAdd.formErrors.name" :rules="formAdd.formRules.name"></v-text-field>
            <v-text-field label="Description" v-model="formAdd.formData.description" :error-messages="formAdd.formErrors.description" :rules="formAdd.formRules.description"></v-text-field>
            <v-radio-group v-model="formAdd.formData.is_active" :error-messages="formAdd.formErrors.is_active" label="Status">
              <v-radio label="Enable" value="1"></v-radio>
              <v-radio label="Disable" value="0"></v-radio>
            </v-radio-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" text @click="formAdd.dialog = false">Cancel</v-btn>
            <v-btn color="primary" text @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="formEdit.dialog" width="500">
      <v-form v-model="formEdit.valid" ref="refFormEdit" lazy-validation @submit.prevent="updateData">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">Edit Module</v-card-title>

          <v-card-text>
            <v-text-field label="Name" v-model="formEdit.formData.name" :error-messages="formEdit.formErrors.name" :rules="formEdit.formRules.name"></v-text-field>
            <v-text-field label="Description" v-model="formEdit.formData.description" :error-messages="formEdit.formErrors.description" :rules="formEdit.formRules.description"></v-text-field>
            <v-radio-group v-model="formEdit.formData.is_active" :error-messages="formEdit.formErrors.is_active" label="Status">
              <v-radio label="Enable" value="1"></v-radio>
              <v-radio label="Disable" value="0"></v-radio>
            </v-radio-group>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" text @click="formEdit.dialog = false">Cancel</v-btn>
            <v-btn color="primary" text @click="updateData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="formPerm.dialog" width="500" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Permission for {{ formPerm.module.description }}</v-card-title>

        <div class="px-6 " style="border-bottom:1px solid #ddd">
          <v-form v-model="formPerm.valid" ref="refFormPerm" lazy-validation @submit.prevent="saveDataPerm">
            <v-text-field label="Add Permission" class="mt-2" v-model="formPerm.formData.perm_name" :error-messages="formPerm.formErrors.name" :rules="formPerm.formRules.perm_name" append-outer-icon="mdi-send" flat outlined solo dense @click:append-outer="saveDataPerm"></v-text-field>
          </v-form>
        </div>
        <v-card-text>
          <v-sheet class="my-2">
            <v-list elevation="1">
              <!-- <template v-for="(item, i) in formPerm.permission"> -->
              <v-list-item v-for="(item, i) in formPerm.permission" :key="i">
                <v-list-item-content>
                  <v-list-item-title>{{ item.perm_name }}</v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-btn icon small color="error" @click="deleteDataPerm(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
              <v-divider v-if="i < formPerm.permission.length - 1" :key="i"></v-divider>
              <!-- </template> -->
            </v-list>
          </v-sheet>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="formPerm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      datatable: {
        loading: null,
        search: "",
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "Action", value: "action", align: "center" },
          { text: "Name", value: "name" },
          { text: "Description", value: "description" },
          { text: "Status", value: "is_active" },
        ],
        data: [],
      },

      formAdd: {
        valid: null,
        dialog: null,
        formData: {
          name: "",
          description: "",
          is_active: false,
        },
        formErrors: {
          name: "",
          description: "",
          is_active: "",
        },
        formRules: {
          name: [(v) => !!v || "Name is required"],
          description: [(v) => !!v || "Description is required"],
        },
      },

      formEdit: {
        valid: null,
        dialog: null,
        formData: {
          id: null,
          name: "",
          description: "",
          is_active: false,
        },
        formErrors: {
          name: "",
          description: "",
          is_active: "",
        },
        formRules: {
          name: [(v) => !!v || "Name is required"],
          description: [(v) => !!v || "Description is required"],
        },
      },

      formPerm: {
        valid: null,
        dialog: null,
        formData: {
          module_id: null,
          perm_name: null,
        },
        module: {},
        permission: [],
        formErrors: {
          perm_name: '',
        },
        formRules: {
          perm_name: [(v) => !!v || "Perm Name is required"],
        },
      },
    };
  },

  methods: {
    async getData(refresh) {
      this.datatable.loading = true;
      if (refresh) this.AxiosStorageRemove("GET", "module/data");
      var params = [];
      await this.$axios
        .get("module/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.modules;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    async saveData() {
      var _valid = this.$refs.refFormAdd.validate();

      if (!_valid) return;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("name", this.formAdd.formData.name);
      formData.append("description", this.formAdd.formData.description);
      formData.append("is_active", this.formAdd.formData.is_active);
      await this.$axios
        .post("module/save", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.formAdd.dialog = false;
            this.showAlert(resData.status, resData.message);
            this.getData(true);
          } else {
            this.formAdd.valid = false;
            this.formAdd.formErrors = resData.data.errors;
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    editData(id, refresh) {
      if (typeof this.$refs.refFormEdit !== "undefined")
        this.$refs.refFormEdit.reset();
      this.showLoadingOverlay(true);
      if (refresh) this.AxiosStorageRemove("GET", "module/detail");

      this.$axios
        .get("module/detail", {
          params: {
            id: id,
          },
          cacheConfig: true,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          this.formEdit.dialog = true;
          var resData = res.data;
          if (resData.status == "success") {
            this.formEdit.formData = resData.data.module;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async updateData() {
      var _valid = this.$refs.refFormEdit.validate();

      if (!_valid) return;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", this.formEdit.formData.id);
      formData.append("name", this.formEdit.formData.name);
      formData.append("description", this.formEdit.formData.description);
      formData.append("is_active", this.formEdit.formData.is_active);
      await this.$axios
        .post("module/update", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.formEdit.dialog = false;
            this.showAlert(resData.status, resData.message);
            this.getData(true);
          } else {
            this.formEdit.valid = false;
            this.formEdit.formErrors = resData.data.errors;
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async deleteData(id) {
      if (!confirm("Delete Data?")) return;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", id);
      await this.$axios
        .post("module/delete", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.getData(true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    editPerm(id, refresh) {
      if (typeof this.$refs.refFormPerm !== "undefined")
        this.$refs.refFormPerm.reset();
      this.showLoadingOverlay(true);
      if (refresh) this.AxiosStorageRemove("GET", "module/permission");

      this.$axios
        .get("module/permission", {
          params: {
            id: id,
          },
          cacheConfig: true,
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          this.formPerm.dialog = true;
          var resData = res.data;
          if (resData.status == "success") {
            this.formPerm.module = resData.data.module;
            this.formPerm.permission = resData.data.permission;
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveDataPerm() {
      var _valid = this.$refs.refFormPerm.validate();

      if (!_valid) return;

      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("perm_name", this.formPerm.formData.perm_name);
      formData.append("module_id", this.formPerm.module.id);
      await this.$axios
        .post("module/permission/save", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.editPerm(this.formPerm.module.id, true);
          } else {
            this.formPerm.formErrors = resData.data.errors;
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async deleteDataPerm(id) {
      if (!confirm("Delete Data?")) return;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("id", id);
      await this.$axios
        .post("module/permission/delete", formData)
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
            this.editPerm(this.formPerm.module.id, true);
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  mounted() {
    this.requiredLogin();
    this.getData();
  },
};
</script>